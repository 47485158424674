.empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 24px;
    text-align: center;
    background: white;
    border: 2px dashed #E5E7EB;
    border-radius: 8px;
  }
  
  .empty-state-icon {
    width: 32px;
    height: 32px;
    color: #9CA3AF;
    margin-bottom: 16px;
  }
  
  .empty-state-title {
    font-weight: 500;
    color: #111827;
    margin: 0 0 4px 0;
  }
  
  .empty-state-text {
    font-size: 14px;
    color: #6B7280;
    margin: 0;
  }
  
  