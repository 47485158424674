.create-notification-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 1rem;
  }
  
  .create-notification-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .create-notification-color-options {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 8px;
  }
  
  .create-notification-color-option {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border: 2px solid transparent;
    border-radius: 6px;
    background: none;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .create-notification-color-option:hover {
    background-color: #F9FAFB;
  }
  
  .create-notification-color-option.selected {
    border-color: var(--color);
    background-color: #F9FAFB;
  }
  
  .create-notification-color-preview {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: var(--color);
  }
  
  .create-notification-color-label {
    font-size: 14px;
    color: #374151;
  }
  
  .create-notification-dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 8px;
  }
  
  .create-notification-confirm-button{
    background-color: #28a745;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 1rem;
    font-weight: 600;
    transition: all 0.2s ease; 
    border: none;
    cursor: pointer;
    border-radius: 0.5rem;
  }

  .create-notification-confirm-button:hover{
    background-color: #218838; 
    transform: translateY(-2px); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }

  .create-notification-cancel-button{
    background-color: #fbbf24;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 2rem;
    font-weight: 600;
    transition: all 0.2s ease;
    border: 0px;
    border-radius: 0.5rem;
  }

  .create-notification-cancel-button:hover{
    background-color: #f59e0b;
    transform: translateY(-2px); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }

  