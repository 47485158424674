.textarea {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 16px;
    line-height: 1.5;
    resize: vertical;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .textarea:focus {
    outline: none;
    border-color: #fbbf24; /* Yellow focus border */
    box-shadow: 0 0 0 4px rgba(251, 191, 36, 0.2); /* Yellow focus glow */
  }
  
  .textarea::placeholder {
    color: #9ca3af; /* Neutral placeholder color */
    font-style: italic;
  }
  