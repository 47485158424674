.notification-types {
    padding: 32px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .notification-types-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
  }
  
  .notification-types-page-title {
    font-size: 28px;
    font-weight: bold;
    margin: 0 0 8px 0;
  }
  
  .notification-types-page-description {
    color: #6B7280;
    margin: 0;
  }

  .notification-types-create-button{
  background-color: #fbbf24;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  font-weight: 600;
  transition: all 0.2s ease; 
  border: none;
  border-radius: 0.5rem;
  }

  .notification-types-create-button:hover{
    background-color: #f59e0b;
    transform: translateY(-2px);
  }

  .notification-types-button-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  
  .notification-types-grid {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 2rem;
  }
  
  @media (min-width: 640px) {
    .notification-types-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1024px) {
    .notification-types-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  