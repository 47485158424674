/* Base Styles */
.lp-page {
  min-height: 100vh;
  background-color: #ffffff;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  color: #1a1a1a;
  overflow-x: hidden;
}

/* Header Styles */
.lp-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(229, 231, 235, 0.5);
  transition: transform 0.3s ease;
}

.lp-header-hidden {
  transform: translateY(-100%);
}

.lp-header-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lp-logo {
  display: flex;
  align-items: center;
}

.lp-logo-text {
  font-size: 1.75rem;
  font-weight: 700;
  color: #1a1a1a;
}

.lp-logo-accent {
  color: #FFD700;
}

.lp-login-button{
  background: #FFD700;
  color: #000000;
  font-weight: 600;
  padding: 0.75rem 2.5rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  border: 1px solid #FFD700;
  font-size: 1rem;
}

.lp-login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.75);
  border: 1px solid #FFD700;
}

.lp-pricing-button{
  background: transparent;
  border: 2px solid #FFD700;
  color: #FFD700;
  font-weight: 600;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  font-size: 1rem;
}

.lp-pricing-button:hover {
  background: rgba(255, 215, 0, 0.1);
  transform: translateY(-2px);
}

.lp-nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.lp-nav-link {
  color: #4b5563;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.lp-nav-link:hover {
  color: #FFD700;
}

/* Hero Section */
.lp-hero {
  position: relative;
  padding: 8rem 2rem 6rem;
  background: linear-gradient(135deg, #000000 0%, #1a1a1a 100%);
  color: white;
  overflow: hidden;
}

.lp-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(255, 215, 0, 0.15) 0%, transparent 70%);
  pointer-events: none;
}

.lp-hero-content {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.lp-hero-text-container {
  text-align: center;
  max-width: 800px;
}

.lp-hero-title {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.lp-hero-title-main {
  font-size: clamp(3rem, 8vw, 5rem);
  font-weight: 800;
  line-height: 1;
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.lp-hero-title-accent {
  color: #FFD700;
  -webkit-text-fill-color: #FFD700;
}

.lp-hero-title-sub {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: 300;
  color: #FFD700;
}

.lp-hero-subtitle {
  font-size: 1.25rem;
  color: #e5e7eb;
  margin-bottom: 3rem;
  line-height: 1.6;
}

.lp-hero-buttons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

.lp-button-primary {
  background: #FFD700;
  color: #000000;
  font-weight: 600;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  border: 2px solid #FFD700;
  font-size: 1rem;
}

.lp-button-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 215, 0, 0.3);
}

.lp-button-secondary {
  background: transparent;
  border: 2px solid #FFD700;
  color: #FFD700;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  font-size: 1rem;
}

.lp-button-secondary:hover {
  background: rgba(255, 215, 0, 0.1);
  transform: translateY(-2px);
}

.lp-hero-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  max-width: 800px;
}

.lp-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(8px);
  transition: transform 0.2s ease;
}

.lp-stat:hover {
  transform: translateY(-4px);
}

.lp-stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #FFD700;
  margin-bottom: 0.5rem;
}

.lp-stat-label {
  color: #e5e7eb;
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/* Features Section */
.lp-features {
  padding: 6rem 2rem;
  background: #ffffff;
}

.lp-features-container {
  max-width: 1280px;
  margin: 0 auto;
}

.lp-section-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 4rem;
  color: #1a1a1a;
}

.lp-title-accent {
  color: #FFD700;
}

.lp-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
}

.lp-feature-card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
  transition: all 0.3s ease;
}

.lp-feature-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.lp-feature-content {
  padding: 2rem;
  text-align: center;
}

.lp-feature-icon-wrapper {
  width: 3.5rem;
  height: 3.5rem;
  background: #fff9db;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem;
}

.lp-feature-icon {
  width: 1.75rem;
  height: 1.75rem;
  color: #FFD700;
}

.lp-feature-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.lp-feature-text {
  color: #4b5563;
  line-height: 1.6;
}

/* Testimonials Section */
.lp-testimonials {
  padding: 6rem 2rem;
  background: #f9fafb;
}

.lp-testimonials-container {
  max-width: 1280px;
  margin: 0 auto;
}

.lp-testimonial {
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem;
  background: white;
  border-radius: 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.lp-testimonial-quote {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #1a1a1a;
  font-style: italic;
  margin-bottom: 2rem;
  position: relative;
}

.lp-testimonial-quote::before,
.lp-testimonial-quote::after {
  content: '"';
  color: #FFD700;
  font-size: 4rem;
  position: absolute;
  height: 1rem;
  line-height: 0;
}

.lp-testimonial-quote::before {
  left: -2rem;
  top: -1rem;
}

.lp-testimonial-quote::after {
  right: -2rem;
  bottom: -1rem;
}

.lp-testimonial-footer {
  text-align: center;
}

.lp-testimonial-author {
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 0.25rem;
}

.lp-testimonial-role {
  color: #6b7280;
  font-size: 0.875rem;
}

/* How It Works Section */
.lp-how-it-works {
  padding: 6rem 2rem;
  background: white;
}

.lp-how-it-works-container {
  max-width: 1280px;
  margin: 0 auto;
}

.lp-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 3rem;
  margin-top: 4rem;
}

.lp-step {
  text-align: center;
  position: relative;
}

.lp-step:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 2rem;
  right: -1.5rem;
  width: 3rem;
  height: 2px;
  background: linear-gradient(90deg, #FFD700 50%, transparent);
}

.lp-step-number {
  width: 4rem;
  height: 4rem;
  background: #FFD700;
  color: black;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 auto 1.5rem;
}

.lp-step-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #1a1a1a;
}

.lp-step-text {
  color: #4b5563;
  line-height: 1.6;
}

/* CTA Section */
.lp-cta {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #000000 0%, #1a1a1a 100%);
  color: white;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.lp-cta::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(255, 215, 0, 0.15) 0%, transparent 70%);
  pointer-events: none;
}

.lp-cta-container {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.lp-cta-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: white;
}

.lp-cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

/* Footer */
.lp-footer {
  background: #1a1a1a;
  color: white;
  padding: 4rem 2rem 2rem;
}

.lp-footer-container {
  max-width: 1280px;
  margin: 0 auto;
}

.lp-footer-content {
  display: grid;
  grid-template-columns: 1.5fr repeat(4, 1fr);
  gap: 4rem;
  margin-bottom: 4rem;
}

.lp-footer-brand {
  grid-column: 1 / span 1; /* Place brand in its own column */
  max-width: 250px; /* Adjust for a more compact layout */
}

.lp-footer-logo {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
}

.lp-footer-tagline {
  color: #9ca3af;
  line-height: 1.6;
}

.lp-footer-links {
  grid-column: span 3; /* Ensures the footer links span three columns */
  display: flex;
  justify-content: space-between; /* Space sections evenly */
  flex-wrap: wrap; /* Wrap sections on smaller screens */
  gap: 2rem; /* Space between each section */
}

.lp-footer-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.lp-footer-title {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.lp-footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.lp-footer-section a {
  color: #9ca3af;
  text-decoration: none;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.lp-footer-section a:hover {
  color: #FFD700;
}

.lp-footer-bottom {
  padding-top: 2rem;
  border-top: 1px solid #374151;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.lp-footer-social {
  display: flex;
  gap: 1.5rem;
}

.lp-footer-social a {
  color: #9ca3af;
  font-size: 1.25rem;
  transition: color 0.2s ease;
}

.lp-footer-social a:hover {
  color: #FFD700;
}

.lp-footer-copyright {
  color: #9ca3af;
  font-size: 0.875rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .lp-footer-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .lp-header-container {
    padding: 1rem;
  }

  .lp-nav-links {
    gap: 1rem;
  }

  .lp-hero {
    padding: 6rem 1rem 4rem;
  }

  .lp-features,
  .lp-testimonials,
  .lp-how-it-works,
  .lp-cta {
    padding: 4rem 1rem;
  }

  .lp-step:not(:last-child)::after {
    display: none;
  }

  .lp-footer-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .lp-footer-bottom {
    flex-direction: column;
    text-align: center;
  }

  .lp-footer-social {
    justify-content: center;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Print Styles */
@media print {
  .lp-header {
    position: static;
    border-bottom: none;
  }

  .lp-hero,
  .lp-features,
  .lp-testimonials,
  .lp-how-it-works,
  .lp-cta {
    page-break-inside: avoid;
  }

  .lp-button-primary,
  .lp-button-secondary {
    display: none;
  }
}

