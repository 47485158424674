:root {
  --color-primary: #ffd700;
  --color-primary-hover: #E6C200;
  --color-background: #f0f2f5;
  --color-header: #000;
  --color-success: #4B9F5B;
  --color-success-hover: #3E7B4E;
  --color-error: #d32f2f;
  --color-border: #ddd;
  --form-max-width: 800px; 
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.business-select-container {
  font-family: 'Poppins', sans-serif;
  background-color: var(--color-background);
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.business-select-card {
  background-color: #ffffff;
  border-radius: 12px;
  width: 100%;
  min-height: 100%;
  max-width: var(--form-max-width); 
  overflow: hidden;
  margin: 0;
  padding:2rem;
}

.business-select-card-header {
  background-color: var(--color-header);
  padding: clamp(24px, 6vw, 24px); 
  text-align: center;
  width: 100%;
}

.business-select-card-title {
  font-weight: 700;
  font-size: clamp(36px, 4.5vw, 36px); /* Larger font size */
  color: var(--color-primary);
  margin-bottom: 8px;
}

.business-select-card-subtitle {
  font-size: clamp(18px, 4vw, 18px);
  color: #fff;
}

.instructions {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  text-align: center;
}

.business-select-input-group {
  text-align: left;
  margin-bottom: 32px; /* Increased spacing */
  width: 100%;
}

.business-select-input-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--color-header);
  font-size: clamp(16px, 3vw, 18px); /* Slightly larger font */
}

.business-select{
  width: 100%;
  padding: clamp(12px, 2.5vw, 14px); /* Adjusted padding */
  border: 1px solid var(--color-border);
  border-radius: 8px;
  font-size: clamp(16px, 3vw, 18px); /* Slightly larger font */
  transition: all 0.3s ease;
}

.business-select:focus,
input.business-select-zip-input[type="text"]:focus {
  border-color: var(--color-primary);
  outline: none;
  box-shadow: 0 0 0 4px rgba(255, 215, 0, 0.2); /* Slightly stronger focus effect */
}

.business-select-input-wrapper {
  position: relative;
  
}

.business-select-icon {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  width: 24px; /* Larger icon */
  height: 24px;
}

.business-select-input-wrapper input.business-select-zip-input[type="text"] {
  width: 100%;
  padding-left: 48px; /* Add padding to match the icon's size and spacing */
  border: 1px solid var(--color-border);
  border-radius: 8px;
  font-size: clamp(16px, 3vw, 18px);
  transition: all 0.3s ease;

}


.action-button {
  background-color: var(--color-primary);
  color: black;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 6px;
  font-size: clamp(16px, 3vw, 18px); /* Slightly larger font */
  font-weight: 500;
  width: 100%;
  transition: all 0.3s ease;
  position: relative;
}

.action-button:hover {
  background-color: var(--color-primary-hover);
}

.action-button:active {
  transform: scale(0.97);
}

.action-button:disabled {
  background-color: var(--color-border);
  cursor: not-allowed;
  opacity: 0.7;
}

.loading-text {
  margin-top: 24px;
  font-size: clamp(14px, 3vw, 16px); /* Slightly larger font */
  color: #666;
  text-align: center;
}

@media (max-width: 550px) {
  .business-select-container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%; /* Full width */
    height: 100vh; /* Full height of the viewport */
    margin: 0; /* Remove any external margins */
    padding: 0; /* Remove internal padding */
    background-color: var(--color-background);
  }

  .business-select-card {
    flex: 1; /* Allow card to fill the available vertical space */
    max-width: none; /* Remove max-width */
    border-radius: 0; /* Remove rounded corners for edge-to-edge layout */
    padding: 1rem; /* Adjust padding for better alignment */
    height: auto; /* Allow card to grow naturally */
  }

  .business-select-card-header {
    padding: 1rem; /* Adjust padding */
  }

  .business-select-card-title {
    font-size: clamp(24px, 5vw, 32px); /* Adjust size for smaller screens */
  }

  .business-select-card-subtitle {
    font-size: clamp(14px, 4vw, 18px); /* Adjust size for smaller screens */
  }

  .business-select-input-group {
    margin-bottom: 1rem; /* Adjust spacing between inputs */
  }

  .action-button {
    width: 100%; /* Ensure the button takes full width */
    padding: 0.75rem 1rem; /* Adjust padding */
    font-size: clamp(14px, 3vw, 16px); /* Adjust font size */
  }
}
  
  
