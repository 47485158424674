.delete-confirm-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 1rem;
  }
  
  .delete-message {
    color: #6B7280;
    margin: 0;
    line-height: 1.5;
  }
  
  .delete-notification-dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .delete-notification-confirm-button{
    background-color: #dc3545;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 2rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    border-radius: 0.5rem;
  }

  .delete-notification-confirm-button:hover{
    background-color: #c82333; 
    transform: translateY(-2px); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }


  .delete-notification-cancel-button{
    background-color: #fbbf24;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 2rem;
    font-weight: 600;
    transition: all 0.3s ease;
    border: 0px;
    border-radius: 0.5rem;
  }

  .delete-notification-cancel-button:hover{
    background-color: #f59e0b;
    transform: translateY(-2px); 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  }


  