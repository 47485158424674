.input {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    font-size: 16px;
    transition: all 0.2s;
  }
  
  .input:focus {
    outline: none;
    border-color: #FFD700;
    box-shadow: 0 0 0 2px rgba(255, 215, 0, 0.1);
  }
  
  .input::placeholder {
    color: #9CA3AF;
  }
  
  .input:disabled {
    background-color: #F3F4F6;
    cursor: not-allowed;
  }
  
  