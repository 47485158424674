.progress-container {
    position: relative;
    width: 100%;
    height: 0.5rem;
    overflow: hidden;
    background-color: #e5e7eb;
    border-radius: 9999px;
  }
  
  .progress-bar {
    height: 100%;
    transition: width 0.2s ease;
  }
  
/* Color for safe range (more than 50% messages left) */
.progress-bar-green {
  background-color: #10b981; /* Green */
}

/* Color for warning range (less than 50% messages left) */
.progress-bar-yellow {
  background-color: #f59e0b; /* Yellow */
}

/* Color for critical range (less than 25% messages left) */
.progress-bar-red {
  background-color: #ef4444; /* Red */
}