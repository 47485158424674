.dashboard {
  padding: 20px;
}

.stat-icon {
  color: #fbbf24; /* Yellow for icons */
  font-size: 24px;
}

.stat-value {
  font-size: 36px; /* Larger font for stats */
  font-weight: bold;
  color: #1f2937; /* Darker stat value color */
  margin: 0;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
  gap: 20px;
}

.stat-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px 12px;
}

.stat-card .card-title {
  color: #6b7280; /* Light gray title for Stat Card */
}

.full-width-container {
  margin-bottom: 30px;
  width: 100%; /* Ensures the card spans the full width */
}

.compose-card,
.recent-messages-card {
  width: 100%; /* Full width of the parent container */
  max-width: 100%; /* Prevent unnecessary overflow */
  margin-top: 30px;
}

.compose-card .card-title , .recent-messages-card .card-title {
  color: #000000; /* Black title for Compose Card */
  font-size: 24px;
}

.compose-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 10px;
}

.compose-label {
  font-weight: bold;
  margin-bottom: 0px;
  color: #000000; 
}

.compose-textarea {
  min-height: 120px;
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  font-size: 16px;
  color: #4b5563;
  resize: vertical;
}


.compose-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.char-count {
  color: #888;
}

.send-button {
  background-color: #fbbf24;
  color: #000;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); 
  transition: background-color 0.3s, transform 0.2s; 
  margin-right: 0.5rem ;
  font-weight: bold;
}

.send-button:hover {
  background-color: #d97706 ;
  transform: translateY(-5px);
}

.send-button:disabled {
  background-color: #d1d5db; /* Light gray for disabled state */
  color: #9ca3af; /* Gray text for contrast */
  cursor: not-allowed; /* Show disabled cursor */
  transform: none; /* Prevent hover effects */
}

.send-button:active {
  transform: translateY(0); /* Return to original position on press */
}

.send-message-icon {
  transition: transform 0.6s ease, opacity 0.6s ease;/* Add smooth transition */
  margin-left: 10px;
}

.send-button:hover .send-message-icon {
  animation: fadeOutToRight 0.6s ease forwards, fadeInFromLeft 0.6s ease 0.6s forwards; /* Apply both animations in sequence */
}

@keyframes fadeOutToRight {
  0% {
    transform: translateX(0) rotate(15deg);
    
    opacity: 1;
  }
  100% {
    transform: translateX(20px) rotate(45deg);
    opacity: 0;
  }
}

@keyframes fadeInFromLeft {
  0% {
    transform: translateX(-20px) rotate(45deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotate(15deg);
    opacity: 1;
  }
}

/*Recently Sent Messages*/

.messages-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.message-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #f9fafb;
  border-radius: 12px;
  padding: 1.25rem;
  border: 1px solid #e5e7eb;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 0.5s ease-out forwards;
}

.message-item:nth-child(even) {
  background-color: #f9fafb;
}

.message-item:hover {
  background-color: #ffffff;
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  border-left: 4px solid #E6C200;
}

.message-content {
  flex: 2;
  min-width: 0; /* Prevents text overflow */
}

.message-text {
  font-size: 1.125rem;
  line-height: 1.5;
  color: #1F2937;
  margin: 0 0 0.5rem 0;
  word-wrap: break-word;
  font-weight: 600;
}

.message-time {
  font-size: 0.875rem;
  color: #6B7280;
  margin: 0;
}

.message-stats {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-left: auto;

}

.message-stats span {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: #6B7280;
}

.message-stats span::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 0.5rem;
  background-color: #FFD700;
  border-radius: 50%;
}

.chevron-icon {
  width: 1rem;
  height: 1rem;
  color: #9CA3AF;
  margin-left: auto;
  transition: transform 0.2s ease;
}

.message-item:hover .chevron-icon {
  transform: translateX(4px);
  color: #FFD700;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add staggered animation delay for each message */
.message-item:nth-child(1) { animation-delay: 0s; }
.message-item:nth-child(2) { animation-delay: 0.1s; }
.message-item:nth-child(3) { animation-delay: 0.2s; }
.message-item:nth-child(4) { animation-delay: 0.3s; }
.message-item:nth-child(5) { animation-delay: 0.4s; }

/* Responsive adjustments */
@media (max-width: 640px) {
  .message-item {
    padding: 1rem;
  }

  .message-stats {
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.no-subscribers-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
}

.no-subscribers-icon-wrapper {
  position: relative;
  display: inline-block;
}

.no-subscribers-icon {
  width: 3rem;
  height: 3rem;
  color: #FFD700;
}

.alert-icon {
  position: absolute;
  bottom: -0.5rem;
  right: -0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  color: #DC2626;
  background: white;
  border-radius: 50%;
}

.no-subscribers-message {
  text-align: center;
}

.no-subscribers-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin: 0 0 0.5rem 0;
}

.no-subscribers-description {
  color: #6B7280;
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 0;
  max-width: 24rem;
}

.no-subscribers-button{
  background-color: #fbbf24;
  padding: 0.75rem 2rem;
  font-size: 0.875rem;
}

.no-subscribers-button:hover{
  background-color: #d97706 ;
  transform: translateY(-0.25rem);
}

.subscriber-count {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem;
  background-color: #f9fafb;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
  color: #4b5563;
  font-size: 0.875rem;
}

.subscriber-count .subscriber-icon {
  width: 1rem;
  height: 1rem;
  color: #FFD700;
}

/* Add animation when the count appears */
.subscriber-count {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Success Dialog Styles */
.success-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  animation: bounceIn 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.success-dialog-icon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-dialog-icon {
  width: 3.5rem;
  height: 3.5rem;
  color: #10b981; /* Green for success */
  animation: flightAnimation 2s ease-in-out infinite;
}

.flying-envelope-icon {
  width: 3rem;
  height: 3rem;
  color: #10b981;
  animation: envelopeFly 3s ease-in-out infinite;
}

@keyframes envelopeFly {
  0% {
    transform: translateY(30px) rotate(0deg);
  }
  25% {
    transform: translateY(15px) rotate(10deg);
  }
  50% {
    transform: translateY(0px) rotate(-10deg);
  }
  75% {
    transform: translateY(-15px) rotate(10deg);
  }
  100% {
    transform: translateY(-30px) rotate(-10deg);
  }

}

@keyframes celebrate {
  0%, 100% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
}


.success-dialog-message {
  text-align: center;
}

.success-dialog-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin: 0 0 0.5rem 0;
}

.success-dialog-description {
  color: #6B7280;
  font-size: 1rem;
  line-height: 1.6;
  text-align: center;
  margin-top: 0.5rem;
}

.success-stats {
  display: flex;
  gap: 2rem;
  justify-content: center;
  margin-top: 1rem;
}

.success-stat {
  display: flex;
  align-items: center;
  gap: 0.5rem;

}

.success-stat-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: #FFD700;
}

.success-dialog-button {
  background-color: #FFD700;
  color: black;
  padding: 0.75rem 5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 0.9rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.success-dialog-button:hover {
  background-color: #E6C200;
  transform: translateY(-1px);
}