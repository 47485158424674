.select {
    position: relative;
    width: 100%;
  }
  
  .select-trigger {
    width: 100%;
    padding: 8px 12px;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .select-trigger:hover {
    border-color: #FFD700;
  }
  
  .select-content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .select-item {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .select-item:hover {
    background-color: #f3f4f6;
  }
  
  