.terms-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
  }
  
  .terms-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .terms-header h1 {
    font-size: 2rem;
    color: #111827;
    margin: 0;
  }
  
  .print-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .print-button:hover {
    background-color: #f3f4f6;
    border-color: #d1d5db;
  }
  
  .print-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .terms-layout {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 2rem;
  }
  
  .terms-nav {
    position: sticky;
    top: 2rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.75rem;
    border: 1px solid #e5e7eb;
  }
  
  .terms-nav-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .terms-nav-header h2 {
    font-size: 1.25rem;
    color: #111827;
    margin: 0;
  }
  
  .terms-nav-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #6b7280;
  }
  
  .terms-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .terms-nav-list li {
    margin-bottom: 0.5rem;
  }
  
  .terms-nav-list a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    color: #4b5563;
    text-decoration: none;
    border-radius: 0.375rem;
    transition: all 0.2s;
  }
  
  .terms-nav-list a:hover {
    background-color: #f3f4f6;
    color: #111827;
  }
  
  .terms-nav-list a.active {
    background-color: #FFD700;
    color: #111827;
  }
  
  .terms-section-icon {
    width: 2rem;
    height: 1rem;
  }
  
  .terms-content {
    padding: 1rem;
    background-color: white;
    border-radius: 0.75rem;
    border: 1px solid #e5e7eb;
  }
  
  .terms-content section {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #e5e7eb;
    scroll-margin-top: 2rem;
  }
  
  .terms-content section:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  
  .terms-content h2 {
    font-size: 1.5rem;
    color: #111827;
    margin: 0 0 1rem 0;
  }
  
  .terms-content p {
    color: #4b5563;
    line-height: 1.6;
    margin: 0 0 1rem 0;
  }
  
  .terms-list ul {
    padding-left: 1.5rem;
    color: #4b5563;
  }
  
  .terms-list li {
    margin-bottom: 0.75rem;
    line-height: 1.6;
  }
  
  .terms-info-card, .terms-contact-card {
    display: flex;
    gap: 1rem;
    padding: 1.5rem;
    background-color: #f9fafb;
    border-radius: 0.75rem;
    margin: 1rem 0;
  }
  
  .terms-info-icon, .terms-contact-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #FFD700;
    flex-shrink: 0;
  }
  
  .terms-scroll-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 3rem;
    height: 3rem;
    background-color: #FFD700;
    border: none;
    border-radius: 50%;
    color: #111827;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .terms-scroll-top:hover {
    background-color: #E6C200;
    transform: translateY(-2px);
  }
  
  a {
    color: #FFD700;
    text-decoration: none;
    transition: all 0.2s;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .terms-layout {
      grid-template-columns: 1fr;
    }
  
    .terms-nav {
      position: relative;
      top: 0;
      height: auto;
      margin-bottom: 1rem;
    }
  
    .terms-header {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }
  }
  
  @media print {
    .terms-nav,
    .terms-scroll-top,
    .print-button {
      display: none;
    }
  
    .terms-layout {
      display: block;
    }
  
    .terms-content {
      border: none;
      padding: 0;
    }
  
    .terms-header {
      border-bottom: none;
    }
  }
  
  /* Dark mode support */
  .dark .terms-container {
    background-color: #1f2937;
  }
  
  .dark .terms-header h1,
  .dark .terms-content h2 {
    color: #f9fafb;
  }
  
  .dark .terms-nav,
  .dark .terms-content {
    background-color: #111827;
    border-color: #374151;
  }
  
  .dark .nav-header,
  .dark .terms-content section {
    border-color: #374151;
  }
  
  .dark .terms-content p,
  .dark .terms-list ul {
    color: #9ca3af;
  }
  
  .dark .nav-list a {
    color: #9ca3af;
  }
  
  .dark .nav-list a:hover {
    background-color: #374151;
    color: #f9fafb;
  }
  
  .dark .info-card,
  .dark .contact-card {
    background-color: #1f2937;
  }
  
  .dark .print-button {
    background-color: #1f2937;
    border-color: #374151;
    color: #9ca3af;
  }
  
  .dark .print-button:hover {
    background-color: #374151;
  }
  
  