.main-content {
  flex: 1;
  padding: 1.5rem;
}

.dashboard-layout {
  min-height: 100vh;
  background-color: #F9FAFB; 
}
  
.mobile-header {
    display: none;
    padding: 1rem;
    background-color: black;
    color: white;
    align-items: center;
    justify-content: space-between;
}

.logo {
  font-size: 1.25rem;
  font-weight: bold;
  color: #fbbf24;
}

.menu-button {
  padding: 0.5rem;
  
}

.menu-icon {
  width: 1.5rem;
  height: 1.5rem;
}

.layout-container {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  width: 16rem;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  padding: 1.5rem;
  border-bottom: 1px solid #1F2937;
}

.sidebar-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fbbf24;
  margin: 0;
}

.sidebar-subtitle {
  font-size: 0.875rem;
  color: #9CA3AF;
  margin: 0.25rem 0 0 0;
}

.sidebar-nav {
  padding: 1rem;
  flex: 1;
}

.nav-button {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between icon and text */
  width: 100%;
  padding: 0.75rem 1rem; /* Padding to make the button larger */
  text-align: left;
  background-color: transparent;
  text-decoration: none; /* Remove underline */
  color: white; /* Default text color */
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.nav-button:hover {
  background-color: white; /* Darker background on hover */
  color: #fbbf24; /* Yellow text on hover */
}

.nav-button.active {
  background-color: #1f2937; /* White background for active */
  color: #fbbf24 !important; /* Yellow text for active */
}

.nav-icon {
  font-size: 1.2rem;
  color: inherit; /* Inherit color from parent */
}

.nav-link-text {
  color: inherit; /* Inherit color from parent */
}

.nav-button subscription {
  color: #F59E0B;
}

.subscription-nav-button {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between icon and text */
  width: 100%;
  padding: 0.75rem 1rem; /* Padding to make the button larger */
  color: #F59E0B; /* Yellow-500 */
  text-align: left;
  background-color: transparent;
  text-decoration: none; /* Remove underline */
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px; /* Rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
  margin-bottom: 1rem;
}

.subscription-nav-button:hover {
  color: #FBBF24; /* Yellow-400 */
  background-color: rgba(245, 158, 11, 0.1); /* Subtle yellow background */
}


.separator-nav {
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid #1f2937; /* Gray-800 equivalent */
}

/* General button styling */
.logout-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 16px;
  width: 100%;
  background-color: transparent;
  border: none;
  color: #f87171; /* Tailwind's Red-400 equivalent */
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.logout-button:hover {
  background-color: rgba(239, 68, 68, 0.1); /* Light red hover effect */
  color: #ef4444; /* Tailwind's Red-500 equivalent */
}

/* Logout link styling */
.logout-link {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: inherit; /* Inherit color from the button */
}

/* Logout icon styling */
.logout-icon {
  width: 20px;
  height: 20px;
  color: inherit;
}

/* Prevent scrolling */
.no-scroll {
  overflow: hidden;
}

/* Fade-in animation */
.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width: 1024px) {
    .mobile-header {
      display: flex;
    }
  
    .sidebar {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 50;
    }
  
    .sidebar.open {
      display: flex;
    }
  }
  
  