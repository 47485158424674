/* Container styles */
.si-container {
    max-width: 72rem;
    margin: 0 auto;
    gap: 2rem;
    
  }
  
  /* Loading state */
  .subscription-loading {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  
  .loading-spinner {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid #FFD700;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
 /* Subscription Inactive Hero Section */
 .si-hero {
  border: 2px solid #fbbf24;
  background-color: #000000;
  color: #ffffff;
  margin-bottom: 2rem;
  border-radius: 8px; /* To add rounded corners */
  overflow: hidden; /* Ensures inner elements are confined */
}

.si-hero-content {
  padding: 2rem;
}

.si-hero-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .si-hero-container {
    flex-direction: row;
    align-items: center;
  }
}

.si-hero-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.si-hero-badge {
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  background-color: #fbbf24;
  color: #000;
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  font-size: 1rem;
  font-weight: 600;
  width: 13rem;
}

.si-hero-icon-rocket {
  height: 1.25rem;
  width: 1.25rem;
}

.si-hero-title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (min-width: 768px) {
  .si-title {
    font-size: 2.5rem;
  }
}

.si-hero-description {
  color: #9ca3af;
  font-size: 1rem;
}

.si-hero-button {
  background-color: #fbbf24;
  color: #000;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 10rem;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  border-radius: 0.375rem;
  font-weight: 600;
  transition: background-color 0.2s;
  font-size: .85rem;
}

.si-hero-button:hover {
  background-color: #f59e0b;
}

.si-hero-icon-loader {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  animation: spin 1s linear infinite;
}

.si-hero-icon-arrow {
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
}

.si-hero-status {
  flex: 1;
  display: flex;
  justify-content: center;
}

.si-hero-status-card {
  background-color: #111827;
  border: 1px solid #1f2937;
  padding: 0.5rem;
  border-radius: 8px;
  width: 28rem;
}

.si-hero-status-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;

  padding: 0.5rem;
}

.si-hero-icon-shield {
  height: 2rem;
  width: 2rem;
  color: #fbbf24;
}

.si-hero-status-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
}

.si-hero-status-description {
  color: #9ca3af;
  text-align: left;
  font-size: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

  @media (min-width: 768px) {
    .si-hero-content {
      flex-direction: row;
      align-items: center;
    }
  }
  
  
  @media (min-width: 768px) {
    .si-hero-title {
      font-size: 2.5rem;
    }
  }
  

  .si-button-icon {
    width: 1rem;
    height: 1rem;
  }
  
  .si-button-icon.spinning {
    animation: spin 1s linear infinite;
  }

  
  /* Features grid */
  .si-features-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
    margin: 2rem 0;
  }
  
  @media (min-width: 768px) {
    .si-features-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
/* Feature Card */
.si-feature-card {
  border: 1px solid #e5e7eb; /* Light gray border */
  border-radius: 0.5rem; /* Rounded corners */
  background-color: #ffffff; /* White background */
  padding: 0.5rem; /* Inner padding */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: transform 0.2s, box-shadow 0.2s;
  
}

.si-feature-card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
}

/* Feature Header */
.si-feature-header{
  background-color: transparent; /* Transparent background */
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: left;
  align-items: center;
}


.si-feature-header-content {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between icon and title */
  margin-bottom: 0;
  
}

.si-feature-icon {
  width: 1.5rem;
  height: 1.5rem;
  color: #fbbf24; /* Yellow icon */
}

.si-feature-title {
  font-size: 1.6rem; /* Larger font */
  font-weight: 600; /* Semi-bold */
  color: #1f2937; /* Dark text */
  margin-bottom: 0;
}

/* Feature Description */
.si-feature-description {
  font-size: 1rem; /* Small font */
  color: #6b7280; /* Gray text */
  margin-bottom: 1rem;
}

/* Feature List */
.si-feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.si-feature-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem; /* Small font */
  font-weight: 500; /* Semi-bold */
  color: #374151; /* Dark gray text */
  margin-bottom: 1rem;
}

.si-feature-check-icon {
  width: 1rem;
  height: 1rem;
  color: #10b981; /* Green icon */
}
  /* CTA section */
  .si-cta-card {
    background-color: #F9FAFB;
    border: none;
  }
  
  .si-cta-content {
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .si-cta-title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  .si-cta-description {
    color: #6B7280;
    max-width: 44rem;
    margin: 0 auto;
  }

  .si-cta-button {
    background-color: #fbbf24;
    color: black;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 1rem 1.75rem;
    border-radius: 0.375rem;
    font-weight: 600;
    transition: background-color 0.2s;
    font-size: .85rem;
  }
  
  .si-cta-button:hover{
    background-color: #f59e0b;
  }
  
  /* Active subscription styles */
.sa-container {
  max-width: 64rem;
  margin: 0 auto;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

/* Header styles */
.sa-header {
  margin-bottom: 1rem;
  
}

.sa-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.sa-description {
  color: #6b7280;
  
}

/* Current Plan Card */
.sa-current-plan-card {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
}

.sa-card-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 700;
  color: #000;
  font-size: 1.5rem;
}

.sa-card-header {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Align items to the left */
  justify-content: flex-start; /* Align content to the top */
  text-align: left; /* Ensure text is aligned to the left */
  margin-bottom: 0;
}

.sa-card-icon {
  color: #fbbf24;
  width: 1.25rem;
  height: 1.25rem;
}

.sa-card-description {
  color: #6b7280;
}

.sa-plan-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.sa-plan-overview {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0rem;
}

.sa-plan-name {
  font-weight: 600;
  font-size: 1.20rem;
}

.sa-plan-renewal {
  color: #6b7280;
  font-size: 0.875rem;
}

.sa-alert-status {
  display: flex;
  align-items: center;
  background-color: #d1fae5;
  color: #10b981;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.sa-alert-icon {
  margin-right: 0.5rem;
  color: #10b981;
}

.sa-plan-usage .sa-usage-item {
  margin-bottom: 1rem;
}

.sa-usage-label {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.sa-usage-bar {
  height: 0.5rem;
  border-radius: 0.25rem;
  background-color: #e5e7eb;
  overflow: hidden;
}

.sa-upgrade-button {
  background-color: #fbbf24;
  color: #000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  font-weight: 600;
  transition: background-color 0.2s;
  border: 0px;
  border-radius: 0.5rem;
}

.sa-upgrade-button:hover {
  background-color: #f59e0b;
}

.sa-upgrade-button .button-icon {
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
}

/* Features Grid */
.sa-features-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

@media (min-width: 768px) {
  .sa-features-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.sa-feature-card {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: #ffffff;
  padding: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sa-feature-header {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.sa-feature-header-content {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between icon and title */
  margin-bottom: 0;
  
}

.sa-feature-title {
  font-size: 1.5rem; /* Larger font */
  font-weight: 600; /* Semi-bold */
  color: #1f2937; /* Dark text */
  margin-bottom: 0;
}


.sa-feature-icon {
  color: #fbbf24;
  width: 1.5rem;
  height: 1.5rem;
}

.sa-feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sa-feature-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: #374151;
}

.sa-feature-check-icon {
  color: #10b981;
  width: 1rem;
  height: 1rem;
}

  
  .warning-alert {
    background-color: #FEE2E2;
    border-color: #EF4444;
    color: #991B1B;
  }
  
  .warning-icon {
    width: 1rem;
    height: 1rem;
    color: #EF4444;
  }
  
  