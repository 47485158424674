.card {
  background-color: #ffffff; /* White background */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  border: 1px solid #e5e7eb; /* Light gray border */
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.2s ease;
}

.card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  transform: translateY(-2px); /* Lift effect */
}

.card-header {
  background-color: transparent; /* Transparent background */
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.card-title {
  font-size: 20px; /* Slightly larger title font */
  font-weight: 600;
  color: #6b7280; 
  margin: 0;

}

.card-description {
  font-size: 0.875rem;
  line-height: 1.5;
  color: #6b7280;
  margin: 0;
}

.card-content {
  padding: 1rem;
  padding-top: 0px;
}

.card-footer {
  display: flex;
  align-items: center;
  padding: 1.5rem;

}

@media (max-width: 640px) {
  .card-header,
  .card-content,
  .card-footer {
    padding: 1rem;
  }
}