.alert {
    position: relative;
    display: flex;

    gap: 0.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid;
  }
  
  .alert.default {
    background-color: #f9fafb;
    border-color: #e5e7eb;
  }
  
  .alert.destructive {
    background-color: #fee2e2;
    border-color: #ef4444;
  }
  
  .alert.success {
    background-color: #ecfdf5;
    border-color: #10b981;
  }
  
  .alert-title {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin: 0;
  }
  
  .alert-description {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #6b7280;
  }
  
  .destructive .alert-description {
    color: #991b1b;
  }
  
  .success .alert-description {
    color: #065f46;
  }
  
  