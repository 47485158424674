.privacy-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    position: relative;
  }
  
  .privacy-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .privacy-header-content h1 {
    font-size: 2.5rem;
    color: #111827;
    margin: 0 0 0.5rem 0;
  }
  
  .privacy-header-meta {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #6b7280;
    font-size: 0.875rem;
  }
  
  .privacy-header-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #FFD700;
  }
  
  .privacy-intro {
    max-width: 800px;
    margin: 0 auto 2rem;
    text-align: center;
    color: #4b5563;
    font-size: 1.125rem;
    line-height: 1.75;
  }
  
  .print-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    color: #4b5563;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .print-button:hover {
    background-color: #f3f4f6;
    border-color: #d1d5db;
  }
  
  .print-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .privacy-layout {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 2rem;
  }
  
  .privacy-nav {
    position: sticky;
    top: 2rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
    padding: 1rem;
    background-color: #f9fafb;
    border-radius: 0.75rem;
    border: 1px solid #e5e7eb;
  }
  
  .privacy-nav-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .privacy-nav-header h2 {
    font-size: 1.25rem;
    color: #111827;
    margin: 0;
  }
  
  .privacy-nav-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #FFD700;
  }
  
  .privacy-nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .privacy-nav-list li {
    margin-bottom: 0.5rem;
  }
  
  .privacy-nav-list a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    color: #4b5563;
    text-decoration: none;
    border-radius: 0.375rem;
    transition: all 0.2s;
    font-size: 0.875rem;
  }
  
  .privacy-nav-list a:hover {
    background-color: #f3f4f6;
    color: #111827;
  }
  
  .privacy-nav-list a.active {
    background-color: #FFD700;
    color: #111827;
  }
  
  .privacy-subsection-list {
    list-style: none;
    padding-left: 2rem;
    margin: 0.25rem 0;
  }
  
  .privacy-subsection-list a {
    font-size: 0.813rem;
    padding: 0.25rem 0.5rem;
  }
  
  .privacy-section-icon {
    width: 1rem;
    height: 1rem;
  }
  
  .privacy-content {
    background-color: white;
    border-radius: 0.75rem;
    border: 1px solid #e5e7eb;
    padding: 2rem;
  }
  
  .privacy-content section {
    margin-bottom: 3rem;
    scroll-margin-top: 2rem;
  }
  
  .privacy-content section:last-child {
    margin-bottom: 0;
  }
  
  .privacy-section-title-icon,
  .privacy-subsection-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #FFD700;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
  
  .privacy-content h2 {
    font-size: 1.5rem;
    color: #111827;
    margin: 0 0 1.5rem 0;
    display: flex;
    align-items: center;
  }
  
  .privacy-content h3 {
    font-size: 1.25rem;
    color: #111827;
    margin: 1.5rem 0 1rem 0;
    display: flex;
    align-items: center;
  }
  
  .privacy-content p {
    color: #4b5563;
    line-height: 1.6;
    margin: 0 0 1rem 0;
  }
  
  .privacy-info-card {
    padding: 1.5rem;
    background-color: #f9fafb;
    border-radius: 0.75rem;
    margin: 1rem 0;
  }
  
  .privacy-info-card.highlight {
    background-color: #fffbeb;
    border: 1px solid #fcd34d;
  }
  
  .privacy-info-card.warning {
    background-color: #fef2f2;
    border: 1px solid #fca5a5;
  }
  
  .privacy-info-card ul {
    padding-left: 1.5rem;
    margin: 1rem 0;
    color: #4b5563;
  }
  
  .privacy-info-card li {
    margin-bottom: 0.5rem;
    line-height: 1.6;
  }
  
  .privacy-rights-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin: 1.5rem 0;
  }
  
  .privacy-rights-card {
    padding: 1.5rem;
    background-color: #f9fafb;
    border-radius: 0.75rem;
    height: 100%;
  }
  
  .privacy-rights-card h3 {
    margin-top: 0;
    color: #111827;
    font-size: 1.125rem;
  }
  
  .privacy-contact-card {
    padding: 2rem;
    background-color: #f9fafb;
    border-radius: 0.75rem;
    margin: 1rem 0;
  }
  
  .privacy-contact-card ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0;
  }
  
  .privacy-contact-card li {
    margin-bottom: 0.5rem;
  }
  
  .privacy-scroll-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 3rem;
    height: 3rem;
    background-color: #FFD700;
    border: none;
    border-radius: 50%;
    color: #111827;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-scroll-top:hover {
    background-color: #E6C200;
    transform: translateY(-2px);
  }
  
  a {
    color: #FFD700;
    text-decoration: none;
    transition: all 0.2s;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .privacy-layout {
      grid-template-columns: 1fr;
    }
  
    .privacy-nav {
      position: relative;
      top: 0;
      height: auto;
      margin-bottom: 1rem;
    }
  
    .privacy-header {
      flex-direction: column;
      gap: 1rem;
    }
  
    .privacy-rights-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media print {
    .privacy-nav,
    .privacy-scroll-top,
    .print-button {
      display: none;
    }
  
    .privacy-layout {
      display: block;
    }
  
    .privacy-content {
      border: none;
      padding: 0;
    }
  
    .privacy-header {
      border-bottom: none;
    }
  
    .privacy-info-card,
    .privacy-rights-card,
    .privacy-contact-card {
      break-inside: avoid;
    }
  }
  
  /* Dark mode support */
  .dark .privacy-container {
    background-color: #1f2937;
  }
  
  .dark .privacy-header h1,
  .dark .privacy-content h2,
  .dark .privacy-content h3 {
    color: #f9fafb;
  }
  
  .dark .privacy-intro {
    color: #9ca3af;
  }
  
  .dark .privacy-nav,
  .dark .privacy-content {
    background-color: #111827;
    border-color: #374151;
  }
  
  .dark .privacy-nav-header {
    border-color: #374151;
  }
  
  .dark .privacy-content p,
  .dark .privacy-info-card ul {
    color: #9ca3af;
  }
  
  .dark .privacy-nav-list a {
    color: #9ca3af;
  }
  
  .dark .privacy-nav-list a:hover {
    background-color: #374151;
    color: #f9fafb;
  }
  
  .dark .privacy-info-card,
  .dark .privacy-rights-card,
  .dark .privacy-contact-card {
    background-color: #1f2937;
  }
  
  .dark .privacy-info-card.highlight {
    background-color: #292524;
    border-color: #854d0e;
  }
  
  .dark .privacy-info-card.warning {
    background-color: #292524;
    border-color: #991b1b;
  }
  
  .dark .print-button {
    background-color: #1f2937;
    border-color: #374151;
    color: #9ca3af;
  }
  
  .dark .print-button:hover {
    background-color: #374151;
  }
  
  