.notification-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
  }
  
  .notification-card-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .notification-type {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .color-dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  
  .notification-name {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
  
  .delete-button {
    padding: 8px;
  }
  
  .delete-button:hover .delete-icon {
    color: #DC2626;
  }
  
  .delete-icon {
    width: 16px;
    height: 16px;
    color: #6B7280;
    transition: color 0.2s;
  }
  
  .notification-card-content {
    padding: 16px;
  }
  
  .notification-description {
    color: #6B7280;
    font-size: 14px;
    margin: 0 0 12px 0;
  }
  
  .message-count {
    font-size: 12px;
    color: #9CA3AF;
  }
  
  