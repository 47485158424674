.business-settings-container {
    padding: 1.5rem; 
    max-width: 60rem; /* Increased container width */
    margin: 0 auto;
    background-color: #f9fafb;
    border-radius: 8px;

  }
  
  .business-settings-title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 16px;
    color: #111827;
  }
  
  .business-settings-instruction {
    font-size: 16px;
    color: #6B7280;
    margin-bottom: 32px;
  }
  
  .business-settings-card {
    margin-bottom: 32px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 1rem; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .card-header-title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
    font-weight: 600;
    color: #111827;
  }
  
  .card-header-icon {
    width: 24px;
    height: 24px;
    color: #fbbf24;
  }
  
  .form-grid {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;
    margin-bottom: 24px;
  }

  .form-grid-3 {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .form-grid-2 {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 24px;
  }
  
  @media (min-width: 768px) {
    .form-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  .form-grid-3 {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr;
    margin-bottom: 24px;
  }
  
  @media (min-width: 768px) {
    .form-grid-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  
  .form-group input:focus {
    border: 2px solid #fbbf24 !important;
    box-shadow: 0 0 0 0px !important;
    outline: none;
}

  
  label {
    font-size: 14px;
    font-weight: 600;
    color: #374151;
  }
  
  input {
    border: 1px solid #d1d5db;
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 16px;
    color: #111827;
    background-color: #f9fafb;
    transition: border-color 0.2s ease;
  }
  
  input:focus {
    outline: none;
    border-color: #fbbf24;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  }
  
  .save-button {
    width: 100%;
    background-color: #fbbf24;
    color: #111827;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease; 
  }
  
  .save-button:hover {
    background-color: #f59e0b;
    transform: translateY(-2px);
  }
  
  @media (min-width: 768px) {
    .save-button {
      width: auto;
    }
  }
  

/* Subscription Card Styles */
.subscription-card {
    height: fit-content;
    padding: 1rem; 
  }
  
  .subscription-card-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    margin-top: 1rem;
  }
  .qr-code-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .qr-code-wrapper {
    margin-bottom: 16px;
  }

  .qr-download-button {
    width: 100%;
    background-color: white;

  }

.qr-download-button,
.copy-button {
  background-color: white;
  border: 1px solid #e4e4e7; /* Subtle gray-black border */
  color: #374151; /* Dark gray text for better contrast */
  font-size: 14px;
  font-weight: 600;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}


.qr-download-button:hover,
.copy-button:hover {
  background-color: #f3f4f6; /* Slightly darker background on hover */
  border-color: #4B5563; /* Darker border on hover */
  color: #1F2937; /* Darker text on hover */
}
  
  .subscription-divider {
    width: 100%;
    position: relative;
    text-align: center;
    margin: 8px 0;
  }
  
  .subscription-divider::before,
  .subscription-divider::after {
    content: '';
    position: absolute;
    top: 50%;
    width: calc(50% - 60px);
    height: 1px;
    background-color: #E5E7EB;
  }
  
  .subscription-divider::before {
    left: 0;
  }
  
  .subscription-divider::after {
    right: 0;
  }
  
  .subscription-divider span {
    background-color: white;
    padding: 0 16px;
    color: #6B7280;
    font-size: 14px;
  }
  
  .subscription-link-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
.subscription-link-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
}
  
  .subscription-link-input {
    background-color: #F9FAFB;
    flex: 1;
    width: 100%;
  }
  
  .copy-button {
    white-space: nowrap;
  }
  
  .button-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
  