.subscribe-container {
  min-height: 100vh;
  padding: 4rem;
  display: flex;
  align-items: flex-start; 
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa, #e4e7eb);
}

.subscribe-card {
  background: white;
  border-radius: 1rem;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  animation: slideUp 0.5s ease-out;
  margin: 0 auto;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.subscribe-header {
  background: #000;
  color: white;
  padding: 2rem;
  text-align: center;
}

.subscribe-header-icon {
  width: 3rem;
  height: 3rem;
  color: #FFD700;
  margin-bottom: 1rem;

}

.subscribe-header h1 {
  font-size: 1.75rem;
  margin: 0 0 0.5rem 0;
}

.subscribe-header p {
  color: #e4e7eb;
  margin: 0;
  font-size: 1rem;
}

.subscribe-form {
  padding: 2rem;
}

.subscribe-form-group {
  margin-bottom: 1.5rem;
}

.subscribe-form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 640px) {
  .subscribe-form-row {
    grid-template-columns: 1fr;
  }
}

label {
  display: block;
  font-weight: 500;
  margin-bottom: 0.5rem;
  color: #374151;
}

.section-label {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #111827;
}

input[type="text"],
input[type="tel"],
input[type="email"] {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid #e5e7eb;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: all 0.2s;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus {
  border-color: #FFD700;
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.1);
}

.subscribe-notification-types {
  margin-bottom: 1.5rem;
}

.checkbox-grid {
  display: grid;
  gap: 0.75rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

/* Style for checkbox */
input[type="checkbox"] {
  appearance: none; 
  width: 20px;
  height: 20px;
  background-color: #f3f4f6;
  border-radius: 2px; 
  cursor: pointer;
  display: inline-block;
  position: relative;
  border: 2px solid #e5e7eb;

}

/* Checked state */
input[type="checkbox"]:checked {
  background-color: #FFD700; /* Background color matching button */
  border-color: #FFD700; /* Border color matching button */
}

/* Checkmark styling */
input[type="checkbox"]:checked::after {
  content: '✓'; 
  font-size: 14px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.consent-section {
  margin: 2rem 0rem 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

}

.consent-section a {
  color: #FFD700;
  text-decoration: none;
}

.consent-section a:hover {
  text-decoration: underline;
}

.error-message {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  color: #991b1b;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.submit-button {
  width: 100%;
  padding: 1rem;
  background-color: #FFD700;
  color: black;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.submit-button:hover:not(:disabled) {
  background-color: #E6C200;
  transform: translateY(-1px);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button-icon {
  width: 1.25rem;
  height: 1.25rem;
}

.button-icon.spinning {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.subscribe-loading-state {
  padding: 3rem;
  text-align: center;
  color: #6b7280;
}

.subscribe-loading-icon {
  width: 2rem;
  height: 2rem;
  margin-bottom: 1rem;
  animation: spin 1s linear infinite;
}

.success-container {
  min-height: 100vh;
  display: flex;
  align-items: flex-start; /* Aligns content to the top */
  justify-content: center;
  padding-top: 10%; /* Add some padding for spacing */
  background: linear-gradient(135deg, #f5f7fa, #e4e7eb);
}

.success-icon {
  width: 4rem;
  height: 4rem;
  color: #10b981;
  margin-bottom: 1.5rem;
}

.success-icon {
  width: 4rem;
  height: 4rem;
  color: #10b981;
  margin-bottom: 1.5rem;
  animation: bounceIn 0.7s ease-out;
}

@keyframes bounceIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  60% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.success-content {
  background: white;
  padding: 3rem;
  border-radius: 1rem;
  text-align: center;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12), 0 0 10px rgba(16, 185, 129, 0.5);
  animation: scaleIn 0.5s ease-out;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.success-content h2 {
  font-size: 1.5rem; 
  margin: 0 0 1rem 0;
  color: #111827;
  font-weight: 700;
}

.success-content p {
  color: #6b7280;
  margin: 0;
}

.confetti-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 100;
}


@media (max-width: 768px) {
  .subscribe-container {
    top: 0;
    left: 0;
    margin: 0 auto; /* Center container horizontally */
    overflow: visible; /* Allow content to extend if needed */
    position: relative; /* Remove fixed position */
  }
   
  body {
   
    height: auto; /* Remove fixed height */
    min-height: 100vh; /* Ensure it covers the viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    display: block; /* Change from flex to block for natural document flow */
}

.subscribe-container {
  margin: 0 auto; /* Center container horizontally */
  overflow: visible; /* Allow content to extend if needed */
  position: relative; /* Remove fixed position */
}
}

@media (max-width: 550px) {

  .subscribe-container {
    top: 0;
    left: 0;
    margin: 0; /* Center container horizontally */
    overflow: visible; /* Allow content to extend if needed */
    position: relative; /* Remove fixed position */
    padding: 0; 
  }

  .subscribe-card {
    max-width: none; /* Remove max-width */
    border-radius: 0; /* Remove border-radius for edge-to-edge effect */
    height: auto; /* Allow it to grow dynamically */

  }

  .subscribe-form-row {
    gap: 0;
  }
  

  .success-content h2 {
    font-size: 1.1rem;
  }

  .success-content p {
    font-size: 0.9rem;
  }

  .success-container {
    min-height: 100vh;
    display: flex;
    align-items: none;
    justify-content: none;

  }

  body {
   
    height: auto; /* Remove fixed height */
    min-height: 100vh; /* Ensure it covers the viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
    display: block; /* Change from flex to block for natural document flow */
}
   
}

  
  
