/* Container Styles */
.choose-subscription-container {
  min-height: 100vh;
  background-color: #f9fafb;
}

/* Header Section */
.choose-subscription-header {
  position: relative;
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 1));
  padding: 6rem 1rem;
  text-align: center;
  color: #fff;
}

.choose-subscription-header-content {
  max-width: 896px; /* Equivalent to Tailwind's max-w-2xl */
  margin: 0 auto;
  z-index: 1;
  position: relative;
}

.choose-subscription-title {
  font-size: 2.25rem; /* Tailwind's text-4xl */
  font-weight: 700; /* Bold */
  line-height: 1.2;
  color: #fff;
}

@media (min-width: 640px) {
  .choose-subscription-title {
    font-size: 3.75rem; /* Tailwind's text-6xl */
  }
}

.choose-subscription-subtitle {
  margin-top: 1.5rem;
  font-size: 1.125rem; /* Tailwind's text-lg */
  line-height: 2rem; /* Tailwind's leading-8 */
  color: #d1d5db; /* Tailwind's text-gray-300 */
}

/* Gradient Overlay */
.choose-subscription-header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: radial-gradient(
    ellipse at center,
    rgba(255, 223, 0, 0.3),
    rgba(0, 0, 0, 0.9)
  );
  opacity: 0.8;
}
 
  /* Plans Section */
  .choose-subscription-plans {
    padding: 5rem 1rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .choose-subscription-plans-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
  }
  
  /* Plan Card */
  .choose-subscription-plan-card {
    position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 1.5rem 0rem 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: visible; 
  }
  
  .choose-subscription-plan-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }


  
  .plan-popular {
    border-color: #fbbf24;
    box-shadow: 0 4px 10px rgba(251, 191, 36, 0.5);
  }
  
  .choose-subscription-plan-popular-badge {
    position: absolute;
    top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fbbf24;
  color: #000;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 600;
  z-index: 1;
  }
  
  /* Plan Details */
 .choose-subscription-plan-header{
  display: flex;
  align-items: center;
  gap: 0.5rem;

  }

  .choose-subscription-plan-title {
    font-size: 1.5rem; /* Larger font */
  font-weight: 600; /* Semi-bold */
  color: #1f2937; /* Dark text */
  margin-bottom: 0;
  padding: 0rem 1rem;
  }
  
  .choose-subscription-plan-description {
    color: #6b7280;
    padding: 0rem 1rem;
  }
  
  .choose-subscription-plan-price {
    margin: 1.5rem 0;
    padding: 0rem 1rem;
  }

  /* Price Span */
.price {
  font-size: 1.75rem; /* Larger font size for the price */
  font-weight: 700;   /* Bold font for emphasis */
  color: #000;        /* Black color */
}

/* /month Span */
.per-month {
  font-size: 1rem;    /* Smaller font size */
  font-weight: 400;   /* Normal weight */
  color: #6b7280;     /* Gray color for subtle appearance */
  margin-left: 0.25rem; /* Spacing between price and /month */
}

  
  .choose-subscription-plan-price span:first-child {
    color: #000;
  }
  
  .choose-subscription-plan-features {
    list-style: none;
    padding: 0rem 1rem;
    margin: 0;

  }
  
  .choose-subscription-plan-feature {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    color: #374151;
  }
  
  .choose-subscription-plan-check-icon {
    color: #10b981;
  }
  
  /* Button */
  .choose-subscription-plan-button {
    width: 100%;
    font-weight: 600;
    padding: 0.75rem;
    border-radius: 0.375rem;
    transition: background-color 0.2s;
    text-align: center;
    margin-top: auto;
  }
  
  .choose-subscription-plan-button:hover {
    background-color: #f59e0b;
  }
  
  .choose-subscription-button-loader {
    margin-right: 0.5rem;
    animation: spin 1s linear infinite;
  }


  .button-yellow {
    background-color: #fbbf24;
    color: #000;
  }
  
  .button-yellow:hover {
    background-color: #f59e0b;
  }
  
  /* Black Button for Other Plans */
  .button-black {
    background-color: #000;
    color: #fff;

  }
  
  .button-black:hover {
    background-color: #1f2937;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* choose-subscription-faq Section */
#choose-subscription-faq-section {
  background-color: #ffffff;
  padding: 6rem 1rem;
}

.choose-subscription-faq-container {
  max-width: 80rem; 
  margin: 0 auto;
  padding: 0 1.5rem;
}

.choose-subscription-faq-header {
  max-width: 36rem; 
  margin: 0 auto;
  text-align: center;
}

.choose-subscription-faq-title {
  font-size: 2rem; 
  font-weight: 700; 
  line-height: 1.25; 
}

@media (min-width: 640px) {
  .choose-subscription-faq-title {
    font-size: 2.5rem; 
  }
}

.choose-subscription-faq-description {
  margin-top: 1.5rem;
  font-size: 1.125rem; 
  line-height: 2rem; 
  color: #4b5563; 
}

.choose-subscription-faq-list-container {
  margin-top: 4rem;
  max-width: 45rem; 
  margin-left: auto;
  margin-right: auto;
}

.choose-subscription-faq-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.choose-subscription-faq-item {
  display: flex;
  flex-direction: column;
}

.choose-subscription-faq-question {
  font-size: 1.125rem; 
  font-weight: 600;
  line-height: 1.75rem; 
}

.choose-subscription-faq-answer {
  margin-top: 0.5rem;
  font-size: 1rem; 
  line-height: 1.75rem; 
  color: #4b5563; 
}
